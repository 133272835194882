import React, { useEffect } from 'react'
import Layout from 'components/layout'
import ProductSEO from 'components/seo/product'
import StickyFooter from 'components/product-sticky-footer'
import Hero from './hero'
import WhoIsTestFor from './who-is-test-for'
import DontRollTheDice from './dont-roll-the-dice'
import DoctorsTrustFellow from './doctors-trust-fellow'
import VasectomyDetailSection from './vasectomy-detail'
import TestimonialsCarousel from 'components/testimonials-carousel'
import Comparison from 'components/comparison'
import FAQ from './faq'
import useVasectomyKitProduct from 'hooks/use-vasectomy-kit-product'
import { formatCurrency } from 'utils/format'
import Urls from 'common/urls'
import ReferencesFootnote from 'components/references-footnote'

const VasectomyTest = () => {
  const product = useVasectomyKitProduct()
  useEffect(() => {
    dataLayer.push({
      event: 'eec.detail',
      ecommerce: {
        detail: {
          products: [
            {
              id: product.id,
              name: product.attributes.name,
              price: formatCurrency(product.price),
            },
          ],
        },
      },
      // @ts-ignore
      branch: window.ab_branch, // branch is defined on the window in our SEO component
    })
  }, [product.attributes.name, product.id, product.price, product.product.name])

  const title = 'Vasectomy Kit | Doctor Reviewed Semen Analysis'
  const seoContent = {
    title: title,
    description:
      'Our at-home vasectomy testing kit allows you to confirm the success of your vasectomy and ensure that you are no longer fertile and are unable to conceive. Get a lab-certified semen analysis result. Doctor Approved. CLIA-Certified Lab.',
    url: Urls.vasectomyKit,
    name: title,
    price: formatCurrency(product.price),
    productImageUrl:
      'https://fellow-images.s3-us-west-2.amazonaws.com/Fellow-Vasectomy-Kit.png',
  }

  const tableContent = {
    checks: [
      {
        headline: 'Affordable',
        text: `$${formatCurrency(product.price, false)}`,
      },
      {
        headline: 'Convenient',
        text: 'Provide sample in the comfort of your home',
      },
      // {
      //   headline: 'Fast results',
      //   text:
      //     'Results in 1.5 days after we receive your sample',
      // },
    ],
    crosses: [
      {
        headline: 'Average Cost',
        text: 'Upwards of $150 or more',
      },
      {
        headline: 'Uncomfortable',
        text: 'Provide your sample awkwardly in a clinic',
      },
      // {
      //   headline: 'Wait',
      //   text: 'Could be up to a week of waiting from the lab',
      // },
    ],
  }

  const references = [
    {
      symbol: '§',
      content:
        'Samplaski et al. Development and validation of a novel mail-in semen analysis system and the correlation between one hour and delayed semen analysis testing; Fertil Steril. 2021;115(4):922-929',
    },
  ]

  return (
    <Layout headerPosition="fixed">
      <ProductSEO
        title={seoContent.title}
        description={seoContent.description}
        url={seoContent.url}
        name={seoContent.name}
        price={seoContent.price}
        productImageUrl={seoContent.productImageUrl}
      />
      <Hero />
      <WhoIsTestFor />
      <DontRollTheDice />
      <DoctorsTrustFellow />
      <VasectomyDetailSection />
      <TestimonialsCarousel dark showCustomers showCTA />
      <Comparison
        heading={'<span translate="no">Fellow </span>compared to a lab.'}
        tableContent={tableContent}
        headingIsHtml
      />
      <FAQ />
      <ReferencesFootnote references={references} />
      <StickyFooter />
    </Layout>
  )
}

export default VasectomyTest
