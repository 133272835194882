import styled from 'styled-components'
import theme from 'styles/theme'
import mq from 'styles/mq'

export const Section = styled.section<{ dark?: boolean }>`
  padding: 9rem 3rem 6rem;
  background: ${({ dark }) => (dark ? theme.color.background.gray : '#fff')};

  ${mq.medium} {
    padding: 8rem 5rem;
  }
`

export const Center = styled.div`
  max-width: 118rem;
  margin: auto;
`

export const Column = styled.div<{
  size?: Array<string | number | null>
}>`
  width: ${props => (props.size && props.size[0] ? props.size[0] : '100%')};

  ${mq.medium} {
    width: ${props => (props.size && props.size[1] ? props.size[1] : '100%')};
  }
`
