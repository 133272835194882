import React from 'react'

import Container from 'components/container'
import TrophyIcon from 'components/icons/trophy'
import HeartIcon from 'components/icons/heart'
import CLIAIcon from 'components/icons/clia'
import {
  Headline,
  Section,
  Triad,
  TriadItem,
  ItemIcon,
  ItemTitle,
  ItemParagraph,
} from './styled'

const DoctorsTrustFellow = () => {
  return (
    <Section>
      <Container>
        <Headline>
          Doctors trust<span translate="no"> Fellow</span>.
        </Headline>
        <Triad>
          <TriadItem>
            <ItemIcon>
              <TrophyIcon />
            </ItemIcon>
            <ItemTitle>Developed by experts</ItemTitle>
            <ItemParagraph>
              <span translate="no">Fellow&apos;s </span>tests were created and
              validated with leaders in men’s reproductive health.
            </ItemParagraph>
          </TriadItem>
          <TriadItem>
            <ItemIcon>
              <HeartIcon />
            </ItemIcon>
            <ItemTitle>Optimized for patients &amp; customers</ItemTitle>
            <ItemParagraph>
              <span translate="no">Fellow </span>tests are convienent,
              affordable, fast, and provide easy-to-understand results.
            </ItemParagraph>
          </TriadItem>
          <TriadItem>
            <ItemIcon>
              <CLIAIcon />
            </ItemIcon>
            <ItemTitle>Clinical-grade quality</ItemTitle>
            <ItemParagraph>
              Our CLIA-certified provides clinically validated analysis on par
              with test results using a fresh sample analyzed within 1 hour.
              <sup>§</sup>
            </ItemParagraph>
          </TriadItem>
        </Triad>
      </Container>
    </Section>
  )
}

export default DoctorsTrustFellow
