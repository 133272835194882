import styled from 'styled-components'
import { heading, paragraph } from 'styles/typography'
import theme from 'styles/theme'
import mq from 'styles/mq'

export const Headline = styled.h1`
  ${heading.xl};
  margin-bottom: 8rem;
  text-align: center;
`

export const Section = styled.section`
  position: relative;
  padding: 8rem 0;

  ${mq.medium} {
    padding: 12rem 0;
  }
`

export const ImgContainer = styled.div`
  position: relative;
  margin: 0 -3rem;
  grid-row: 1;

  ${mq.medium} {
    margin: 0;
  }

  ${mq.large} {
    grid-row: auto;
  }
`

export const Triad = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  ${mq.medium} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const TriadItem = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.medium} {
    max-width: 36rem;
  }

  &:last-of-type {
    svg {
      margin: auto 0.5rem;
    }
  }
`

export const ItemIcon = styled.div`
  color: ${theme.color.accent.secondary};
  font-size: 10rem;
`

export const ItemTitle = styled.div`
  ${heading.s};
  line-height: 2.4rem;
  padding: 0 1rem 2rem;
`

export const ItemParagraph = styled.div`
  ${paragraph.small};
  padding: 0 1rem 4rem;
`
