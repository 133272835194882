import styled from 'styled-components'
import mq from 'styles/mq'
import Img from 'gatsby-image'
import { heading, paragraph } from 'styles/typography'

export const SectionTitle = styled.h2`
  ${heading.l};
  text-align: left;

  ${mq.medium} {
    text-align: center;
  }
  max-width: 90rem;
  margin: 0 auto 2rem auto;
`

export const Paragraph = styled.p`
  ${paragraph.small}
  max-width: 90rem;
  margin: auto auto 1.6rem;

  ${mq.medium} {
    text-align: center;
  }
`

export const DiceImg = styled(Img as any)`
  width: 20rem;
  margin: 0 auto 2rem;
`

export const Subtitle = styled.h4`
  text-align: left;
  margin-top: 4rem;

  ${mq.medium} {
    text-align: center;
  }
`