import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const HouseIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 98 97">
    <path
      d="M64.923 26.62c-7.223 0-13.366 4.654-15.626 11.115h-1.882C45.15 31.273 39.01 26.62 31.787 26.62c-9.135 0-16.568 7.433-16.568 16.568 0 4.772 2.041 9.07 5.283 12.096h-.02L47.015 81.82h2.617L75.73 55.717c3.521-3.042 5.76-7.524 5.76-12.53.002-9.131-7.431-16.566-16.567-16.566zM7.405 45.902H1.882v5.523h5.523v-5.523zM95.295 45.954h-5.527v5.523h5.527v-5.523zM15.132 77.918l3.906 3.904 7.81-7.811-3.907-3.905-7.809 7.812zM77.584 81.823l3.906-3.905-7.81-7.811-3.905 3.904 7.81 7.812zM22.985 23.842l3.906-3.904-7.81-7.813-3.906 3.905 7.81 7.812zM69.819 19.936l3.906 3.904 7.809-7.811-3.906-3.905-7.81 7.812z"
      fill="currentColor"
    />
  </Svg>
)


export default HouseIcon
