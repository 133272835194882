import styled from 'styled-components'
import Img from 'gatsby-image'
import mq from 'styles/mq'
import { heading, paragraph } from 'styles/typography'
import { Column } from '../styled'

export const Headline = styled.h1`
  ${heading.xl};
  margin: 0 auto 8rem !important;
  max-width: 80rem;

  ${mq.medium} {
    margin: 0 auto 12rem !important;
    text-align: center;
  }
`

export const VasectomyDetailImageColumn = styled(Column as any)`
  margin-top: 0;
  order: 2;

  ${mq.medium} {
    order: 1;
  }
`

export const VasectomyDetailContentWrap = styled.div`
  ${mq.large} {
    width: 36.1rem;
  }
`

export const VasectomyDetailImageWrap = styled.div`
  width: 100%;
  margin-top: 2rem;

  ${mq.medium} {
    margin-top: 0;
  }

  ${mq.large} {
    margin: -8rem 1rem -11rem;
  }

  img {
    width: 100%;
  }
`

export const VasectomyDetailContentColumn = styled(Column as any)`
  display: flex;

  ${mq.medium} {
    order: 2;
  }

  ${mq.large} {
    padding-left: 5rem;
  }
`

export const VasectomyDetailImage = styled(Img as any)`
  width: 24rem;
  margin: 0 auto 2rem;

  ${mq.small} {
    width: 30rem;
  }

  ${mq.medium} {
    margin-bottom: 0;
  }

  ${mq.large} {
    width: 40rem;
  }
`

export const SectionTitleSmall = styled.h5`
  ${heading.m};
`

export const SectionParagraph = styled.p`
  ${paragraph.small};
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  ${mq.medium} {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8rem;
  }

  ${mq.desktop} {
    margin-bottom: 16rem;
  }
`