import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from 'components/container'
import { StyledLink } from 'components/links'
import {
  Heading,
  Img,
  List,
  Section,
  Subtitle,
  Wrapper,
  LinkSubtitle,
} from './styled'
import Urls from 'common/urls'

type QueryResult = {
  noSperm: FluidImage
}

const query = graphql`
  query {
    noSperm: file(relativePath: { eq: "no-sperm.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const WhoIsTestFor = () => {
  const data = useStaticQuery<QueryResult>(query)

  return (
    <Section>
      <Container>
        <Heading>Who is the Vasectomy Test for?</Heading>
        <Subtitle>
          This test is for you if:
        </Subtitle>
        <Wrapper>
          <List>
            <li>A doctor recommended this test</li>
            <li>You are testing your vasectomy to ensure the procedure was successful</li>
            <LinkSubtitle>
              Had a vasectomy reversal? Order our&nbsp;
              <StyledLink to={Urls.kit}>semen analysis.</StyledLink>
            </LinkSubtitle>
          </List>
          <Img
            // @ts-ignore
            fluid={{
              ...data.noSperm.childImageSharp.fluid,
            }}
            fadeIn={false}
          />
        </Wrapper>
      </Container>
    </Section>
  )
}

export default WhoIsTestFor
