import React from 'react'
import { FluidObject } from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { Center, Section } from '../styled'

import {
  Headline,
  VasectomyDetailImageColumn,
  VasectomyDetailImageWrap,
  VasectomyDetailContentColumn,
  VasectomyDetailContentWrap,
  VasectomyDetailImage,
  Row,
  SectionTitleSmall,
  SectionParagraph,
} from './styled'
import { ScreenSize } from '../../../styles/mq'

type FluidImage = {
  childImageSharp: {
    fluid: FluidObject
  }
}

type QueryResult = {
  calendar: FluidImage
  vasectomyKit: FluidImage
  results: FluidImage
  repeat: FluidImage
}

const query = graphql`
  query {
    calendar: file(relativePath: { eq: "calendar.png" }) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 528) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vasectomyKit: file(
      relativePath: { eq: "vasectomy-kit-with-container.png" }
    ) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 528) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    results: file(relativePath: { eq: "vasectomy-test-results.png" }) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 528) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    repeat: file(relativePath: { eq: "repeat.png" }) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 528) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const VasectomyDetailSection = () => {
  const data = useStaticQuery<QueryResult>(query)

  return (
    <Section>
      <Center>
        <Headline>
          How to test the success of your vasectomy from home:
        </Headline>
        <Row>
          <VasectomyDetailImageColumn>
            <VasectomyDetailImageWrap>
              <VasectomyDetailImage
                fluid={{
                  ...data.calendar.childImageSharp.fluid,
                  sizes: `(min-width: ${ScreenSize.LG}px) 500px, (min-width: ${ScreenSize.MD}px) 50vw, 100vw`,
                }}
                alt="A pair of calendars"
              />
            </VasectomyDetailImageWrap>
          </VasectomyDetailImageColumn>
          <VasectomyDetailContentColumn>
            <VasectomyDetailContentWrap>
              <SectionTitleSmall>
                1. Wait 12 weeks and clear out your system.
              </SectionTitleSmall>
              <SectionParagraph>
                The American Urological Association recommends ejaculating at
                least 20-30 times during a 12 week period following your
                vasectomy. During this time you and your partner should use
                other forms of birth control because there may be lingering
                sperm cells in your reproductive tract.
              </SectionParagraph>
            </VasectomyDetailContentWrap>
          </VasectomyDetailContentColumn>
        </Row>
        <Row>
          <VasectomyDetailImageColumn>
            <VasectomyDetailImageWrap>
              <VasectomyDetailImage
                fluid={{
                  ...data.vasectomyKit.childImageSharp.fluid,
                  sizes: `(min-width: ${ScreenSize.LG}px) 500px, (min-width: ${ScreenSize.MD}px) 50vw, 100vw`,
                }}
                alt="The Fellow Vasectomy Test with Specimen Container"
              />
            </VasectomyDetailImageWrap>
          </VasectomyDetailImageColumn>
          <VasectomyDetailContentColumn>
            <VasectomyDetailContentWrap>
              <SectionTitleSmall>
                2. Conveniently test at home.
              </SectionTitleSmall>
              <SectionParagraph>
                With a<span translate="no"> Fellow </span>Vasectomy Test you can
                produce a sample at home and mail it back (shipping is already
                included). We&apos;ll analyze your sample in our CLIA-certified
                lab to look for any sperm.
              </SectionParagraph>
            </VasectomyDetailContentWrap>
          </VasectomyDetailContentColumn>
        </Row>
        <Row>
          <VasectomyDetailImageColumn>
            <VasectomyDetailImageWrap>
              <VasectomyDetailImage
                fluid={{
                  ...data.results.childImageSharp.fluid,
                  sizes: `(min-width: ${ScreenSize.LG}px) 500px, (min-width: ${ScreenSize.MD}px) 50vw, 100vw`,
                }}
                alt="A graphic of the an envelope and a phone displaying results from the Fellow Vasectomy Test"
              />
            </VasectomyDetailImageWrap>
          </VasectomyDetailImageColumn>
          <VasectomyDetailContentColumn>
            <VasectomyDetailContentWrap>
              <SectionTitleSmall>
                3. Clinically validated testing.
              </SectionTitleSmall>
              <SectionParagraph>
                We&apos;ll email you a link to a secure webpage with your
                easy-to-understand results. If your healthcare provider
                recommended this test, we can send them a copy of your results
                as well.
              </SectionParagraph>
            </VasectomyDetailContentWrap>
          </VasectomyDetailContentColumn>
        </Row>
        <Row>
          <VasectomyDetailImageColumn>
            <VasectomyDetailImageWrap>
              <VasectomyDetailImage
                fluid={{
                  ...data.repeat.childImageSharp.fluid,
                  sizes: `(min-width: ${ScreenSize.LG}px) 500px, (min-width: ${ScreenSize.MD}px) 50vw, 100vw`,
                }}
                alt="A symbol for 'repeat'"
              />
            </VasectomyDetailImageWrap>
          </VasectomyDetailImageColumn>
          <VasectomyDetailContentColumn>
            <VasectomyDetailContentWrap>
              <SectionTitleSmall>4. Repeat if necessary.</SectionTitleSmall>
              <SectionParagraph>
                If your results indicate you have sperm in your sample, or if
                your doctor requires two tests to deem a vasectomy successful,
                test again conveniently at home. Some doctors advise to get
                tested a year after your procedure to ensure that your vas
                deferens (what was cut during the vasectomy) did not repair
                itself.
              </SectionParagraph>
            </VasectomyDetailContentWrap>
          </VasectomyDetailContentColumn>
        </Row>
      </Center>
    </Section>
  )
}

export default VasectomyDetailSection
