import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const TrophyIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props}>
    <path
      d="M18.5 54.71V32.289a25 25 0 0 1 50 0V54.71a25 25 0 0 1-50 0zm2.68-22.421V54.71a22.321 22.321 0 0 0 44.641 0V32.289a22.321 22.321 0 0 0-44.641 0zM23.728 54.6V32.4a19.773 19.773 0 0 1 39.544 0v22.2a19.773 19.773 0 0 1-39.544 0zm2.679-22.2v22.2a17.093 17.093 0 0 0 34.185 0V32.4a17.093 17.093 0 0 0-34.185 0zm3.056 22.437v-9.77h2.68v9.766a11.357 11.357 0 0 0 22.714 0v-9.766h2.679v9.766a14.037 14.037 0 0 1-28.073 0zM51.4 59.783H35.619V56.44c6.527 0 6.715-3.7 6.715-4.119v-.082a3.912 3.912 0 0 0-.3-1.722A3.194 3.194 0 0 0 39.7 48.74c-1.856-.445-3.735-2.393-4.9-5.084a5.373 5.373 0 0 1-.665.045 5.653 5.653 0 0 1-1.484-11.081c-.213-1.23-.365-2.072-.366-2.081h22.448c0 .008-.155.855-.372 2.108A5.655 5.655 0 0 1 52.777 43.7a5.349 5.349 0 0 1-.565-.03 11.669 11.669 0 0 1-2.11 3.29 5.825 5.825 0 0 1-2.788 1.779 3.194 3.194 0 0 0-2.336 1.776 3.914 3.914 0 0 0-.3 1.722v.082c0 .422.188 4.119 6.715 4.119v3.343zM35.661 33.339a.961.961 0 0 0-.235.752 37.315 37.315 0 0 0 2.853 10.863.194.194 0 0 0 .156.112.15.15 0 0 0 .044-.006.136.136 0 0 0 .093-.158 55.707 55.707 0 0 1-1.195-10.944.98.98 0 0 0-.97-.949H36.4a.985.985 0 0 0-.739.33zm-4.748 4.717a3.359 3.359 0 0 0 .917 2.32 3.155 3.155 0 0 0 2.22 1 11.364 11.364 0 0 1-.2-1.09 158.282 158.282 0 0 0-.809-5.358 3.334 3.334 0 0 0-2.128 3.128zm22.254 2.232a9.84 9.84 0 0 1-.2 1.085 3.283 3.283 0 0 0 3.03-3.316 3.379 3.379 0 0 0-.569-1.885 3.247 3.247 0 0 0-1.46-1.2 151.825 151.825 0 0 0-.801 5.316zm1.166-11.5a11.386 11.386 0 0 0-21.665 0h-2.784a14.066 14.066 0 0 1 27.231 0z"
      fill="currentColor"
    />
  </Svg>
)

export default TrophyIcon
