import { graphql, useStaticQuery } from 'gatsby'

type QueryResult = {
  allStripeSku: AllStripeSkuType
}

const query = graphql`
  query {
    allStripeSku(sort: { fields: created, order: ASC }) {
      edges {
        node {
          id
          price
          product {
            name
          }
          attributes {
            name
          }
          created
        }
      }
    }
  }
`

const useVasectomyKitProduct = () => {
  const { allStripeSku } = useStaticQuery<QueryResult>(query)
  return allStripeSku.edges[2].node
}

export default useVasectomyKitProduct
