import styled from 'styled-components'
import ImgBase from 'gatsby-image'

import theme from 'styles/theme'
import { heading } from 'styles/typography'
import mq from 'styles/mq'

export const Img = styled(ImgBase)`
  width: 20rem;
  margin-top: 4rem;

  ${mq.desktop} {
    margin-top: -3rem;
    width: 16rem;
  }
`

export const Section = styled.section`
  background: ${theme.color.background.gray};
  padding: 8rem 0 4rem;

  ${mq.desktop} {
    padding-bottom: 8rem;
  }
`

export const List = styled.ul`
  li {
    margin: 1rem 0;
    list-style: none;

    &:before {
      content: '';
      float: left;
      width: 0.4rem;
      height: 0.4rem;
      margin: 0.6em 0.5em 0 -1em;
      background: ${theme.color.accent.primary};
    }
  }

  &:nth-of-type(2) {
    li:first-of-type {
      margin-top: 0;
    }
  }

  ${mq.small} {
    min-width: 100%;
  }

  ${mq.desktop} {
    min-width: 33rem;

    &:nth-of-type(2) {
      li:first-of-type {
        margin: 1rem 0;
      }
    }
  }

  ${mq.large} {
    min-width: 40rem;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2rem;

  ${mq.desktop} {
    flex-direction: row;
    justify-content: space-between;
    margin-left: 0;
    padding-left: 2rem;
  }
`

export const Heading = styled.h1`
  ${heading.l};
`

export const Subtitle = styled.h4`
  ${mq.desktop} {
    margin-bottom: 0;
  }
`

export const LinkSubtitle = styled.h4`
  margin-top: 2rem;
  margin-left: -2rem;
  align-self: flex-start;

  ${mq.desktop} {
    margin-top: 4rem;
    margin-bottom: 0;
    align-self: auto;
  }
`